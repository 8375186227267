import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import MuiTypography from '@mui/material/Typography'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'

const CssTypography = styled(MuiTypography)((props) => {
  const { theme, style, source } = props
  return { ...theme?.typography, ...theme?.typography?.[source], ...style }
})

const Typography = (props) => {
  const { children } = props
  const { theme } = useContext(ThemeContext)
  return (
    <CssTypography {...props} theme={theme}>
      {children}
    </CssTypography>
  )
}

export default Typography
