import React, { useState } from 'react'
import QuestionCreator from '../components/QuestionCreator'
import Button from '../components/Button'
import { useNavigate } from 'react-router-dom'

const CreateForms = () => {
  const [inputType, setInputType] = useState('text')
  const navigate = useNavigate()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Button onClick={() => navigate(-1)}>{'< Back'}</Button>
      <QuestionCreator />
    </div>
  )
}

export default CreateForms
