import React, { useEffect, useState } from 'react'
import Button from '../components/Button'
import { getForms } from '@appt-digital/frontend-api-lib-flytt'
import { useNavigate } from 'react-router-dom'

const ViewForms = () => {
  const navigate = useNavigate()
  const [forms, setForms] = useState([])
  useEffect(() => {
    const getData = async () => {
      const res = await getForms()
      setForms(res?.data)
      console.log('forms', res.data)
    }
    getData()
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Button onClick={() => navigate(-1)}>{'< Back'}</Button>
      {forms?.map((form) => (
        <Button
          key={form?.id}
          onClick={() =>
            navigate('/dashboard/forms/display', {
              state: {
                form,
                id: form.id,
              },
            })
          }
        >
          {form?.name}
        </Button>
      ))}
    </div>
  )
}

export default ViewForms
