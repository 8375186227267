import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AlertContext } from '../../../context/AlertContext'
import { OrganisationContext } from '../../../context/OrganisationContext'
import { UserContext } from '../../../context/UserContext'
import { accessTokenLogin } from '../../../handlers/requests/auth/loginHandler'
import LoadingAnim from '../../../ui-library/components/loadingAnim/LoadingAnim'
import Button from './components/Button'

const Forms = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setUser } = useContext(UserContext)
  const { setOrganisations } = useContext(OrganisationContext)
  const [isLoading, setIsLoading] = useState(
    !!(searchParams.get('token') && !sessionStorage.getItem('loggedIn'))
  )
  const { setAlert } = useContext(AlertContext)

  useEffect(() => {
    (async () => {
      if (!sessionStorage.getItem('loggedIn')) {
        let accessToken = searchParams.get('token')
        if (accessToken) {
          if (
            await accessTokenLogin(
              accessToken,
              setUser,
              setOrganisations,
              setAlert
            )
          ) {
            sessionStorage.setItem('loggedIn', true)
            setIsLoading(false)
          }
        }
      }
    })()
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: 600,
        width: '100%',
      }}
    >
      {isLoading ? (
        <div
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h1>Loading</h1>
          <LoadingAnim />
        </div>
      ) : (
        <>
          <Button onClick={() => navigate('/dashboard/forms/create')}>
            Create Form
          </Button>
          <Button onClick={() => navigate('/dashboard/forms/list')}>
            Edit Forms
          </Button>
          <Button onClick={() => navigate('/dashboard/forms/view')}>
            Display Forms
          </Button>
          <Button onClick={() => navigate('/dashboard/forms/submitted')}>
            Submitted Forms
          </Button>
        </>
      )}
    </div>
  )
}

export default Forms
