import {
  deleteUser,
  getUsers,
  inviteUser,
  resetPassword,
  updateUser,
} from '@appt-digital/frontend-api-lib-flytt'
import uiText from '../../../resources/uiText'

const editAppUser = async (id, data, setAlert) => {
  const values = {
    firstname: data?.values?.firstname,
    lastname: data?.values?.lastname,
    title: data?.values?.title,
    homeNumber: data?.values?.homePhone,
    mobile: data?.values?.mobile,
    addressLineOne: data?.values?.addressLineOne,
    addressLineTwo: data?.values?.addressLineTwo,
    city: data?.values?.city,
    postcode: data?.values?.postcode,
    dob: data?.values?.dob,
  }
  const req = await updateUser(id, values)
  console.log(req)
  if (req.name === 'AxiosError') {
    setAlert({
      open: true,
      severity: 'error',
      message: 'Error updating user',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'User updated',
    action: false,
  })
}

const addAdminUserHandler = async (data, setAlert) => {
  //   const addUserData = {
  //     username: data.values.emailAddress,
  //     email: data.values.emailAddress,
  //     firstname: data.values.firstname,
  //     lastname: data.values.lastname,
  //     title: data.title.value || data.title,
  //     departmentKey: data.values.departmentKey,
  //     jobTitle: data.values.jobTitle,
  //   }
  //   // const roles = await getRoleByTitle('Admin')
  //   const req = await inviteUser(addUserData, roles[0]?.id)
  //   if (req.name === 'AxiosError') {
  //     return setAlert({
  //       open: true,
  //       severity: 'error',
  //       message: 'Error adding user',
  //       action: false,
  //     })
  //   }
  //   setAlert({
  //     open: true,
  //     severity: 'success',
  //     message: 'User added.',
  //     action: false,
  //   })
}

const addAppUserHandler = async (data, setAlert) => {
  const values = {
    username: data.values.emailAddress,
    email: data.values.emailAddress,
    firstname: data.values.firstname,
    lastname: data.values.lastname,
    departmentKey: data.values.departmentKey,
    jobTitle: data.values.jobTitle,
    type: '',
  }

  try {
    // const roles = await getRoleByTitle('User')
    const req = await inviteUser(values)
    if (req.name === 'AxiosError') {
      return setAlert({
        open: true,
        severity: 'error',
        message: 'Error adding user',
        action: false,
      })
    }
    setAlert({
      open: true,
      severity: 'success',
      message: 'User added.',
      action: false,
    })
  } catch (err) {}
}

const getAppUsers = async (search = '', type = '') => {
  const params =
    '{ "$or": [{"email": {"$contL": "' +
    search +
    '"}}, {"firstname": {"$contL": "' +
    search +
    '"}}, {"lastname": {"$contL": "' +
    search +
    '"}}], "type": "' +
    type +
    '"}'

  return await getUsers()
}

const deleteAppUser = async (id, setAlert) => {
  const req = await deleteUser(id)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: 'Error deleting user',
      action: false,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'User deleted.',
    action: false,
  })
}
const resetAppUser = async (email, setAlert) => {
  const req = await resetPassword(email)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: uiText.errors.reset,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'Password reset request sent',
  })
}
export {
  editAppUser,
  addAdminUserHandler,
  addAppUserHandler,
  getAppUsers,
  deleteAppUser,
  resetAppUser,
}
