import React from 'react'
import Button from './components/Button'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { embedLogin } from '@appt-digital/frontend-api-lib-flytt'

const EmbedEdit = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const getEmbedLogin = async () => {
      const res = await embedLogin(queryParameters.get('access_token'))
      if (res.status != 200) {
        // show error ??
      } else {
        setUser(res.data)
      }
    }
    getEmbedLogin()
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: 600,
        width: '100%',
      }}
    >
      <Button onClick={() => navigate('/dashboard/forms/create')}>
        Create Form
      </Button>
      <Button onClick={() => navigate('/dashboard/forms/list')}>
        Edit Forms
      </Button>
    </div>
  )
}

export default EmbedEdit
