import { resetPassword } from '@appt-digital/frontend-api-lib-flytt'
import uiText from '../../../resources/uiText'

const resetHandler = async (email, navigate, setAlert) => {
  const req = await resetPassword(email)
  if (req.name === 'AxiosError') {
    return setAlert({
      open: true,
      severity: 'error',
      message: uiText.errors.reset,
    })
  }
  setAlert({
    open: true,
    severity: 'success',
    message: 'Please check your email',
  })
  navigate('/login')
}

export { resetHandler }
