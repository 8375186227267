import React from 'react'
import TextField from './TextField'
import uuid from 'react-uuid'
import IconButton from './IconButton'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import Typography from './Typography'
import color from '../styles/color'

const DynamicList = (props) => {
  const { data, setData, type, label, heading, setRemoved, addText } = props
  const handleAddBox = () => {
    setData(type, uuid(), '')
  }
  const handleRemoveBox = (id, type) => {
    const editedBoxArray = data[type].filter((item) => item.id !== id)
    setRemoved(type, editedBoxArray)
  }
  return (
    <div>
      <Typography source="subtitle2">{heading}</Typography>
      {data?.[type]?.map((box, index) => {
        return (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <TextField
              {...props}
              label={label}
              value={box.value}
              onChange={(e) => setData(type, box.id, e.target.value)}
              source={'bordered'}
            />
            <IconButton
              style={{ marginTop: 40 }}
              source="error"
              onClick={() => handleRemoveBox(box.id, type)}
              icon={RemoveIcon}
            />
          </div>
        )
      })}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 5,
        }}
      >
        <IconButton onClick={() => handleAddBox()} icon={AddIcon} />

        <Typography
          {...props}
          style={{ color: color.primary, fontSize: 14, marginLeft: 5 }}
        >
          {addText}
        </Typography>
      </div>
    </div>
  )
}
export default DynamicList
