import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../components/Button'
import TextField from '../components/TextField'
import Checkbox from '../components/Checkbox'
import Dropdown from '../components/Dropdown'
import DropZone from '../../../../ui-library/components/DropZone'
import DateSelect from '../../../../ui-library/components/DateSelect'
import {
  formSubmissions,
  createFormSubmission,
  submitForm,
  updateFormSubmission
} from '@appt-digital/frontend-api-lib-flytt'
import { AlertContext } from '../../../../context/AlertContext'

const GoToEvaluator = {
  GT: '>',
  GTE: '>=',
  LS: '<',
  LSE: '<=',
  EQ: '=',
  NEQ: '!=',
  SKIP: 'Skip'
}

const DisplayForms = () => {
  const { state } = useLocation()
  const [form, setForm] = useState(state?.form?.form ? [...state.form.form] : [])
  const [sectionHistory, setSectionHistory] = useState([0])
  const [sectionIndex, setSectionIndex] = useState(0)
  const [answers, setAnswers] = useState({})
  const [dropFile, setDropFile] = useState(null)
  const [postPath, setPostPath] = useState('')
  const [patchPath, setPatchPath] = useState('')
  const { setAlert } = useContext(AlertContext)
  const navigate = useNavigate()
  const handleAnswers = (value, questionIndex) => {
    const newAnswers = {...answers}
    newAnswers[form[sectionIndex]?.questions[questionIndex]?.returnAPIName]  = value
    setAnswers(newAnswers)
  }
  useEffect(() => {
    const createFormSub = async () => {
      if (state.updating) {
        let theForm = await formSubmissions(state.id)
        console.log(state, theForm)
        setForm(theForm?.data?.data)
        setPostPath(theForm?.data?.postPath)
        setPatchPath(theForm?.data?.patchPath)
        setAnswers(theForm?.data?.answers)
      } else {
        const res = await createFormSubmission(state.id)
        setPostPath(res?.data?.postPath)
        setPatchPath(res?.data?.patchPath)
      }
    }
    createFormSub()
  }, [])
  const goToNextScreen = (forward) => {
    const goToPage = (newIndex) => {
      // Set new index
      setSectionIndex(newIndex)

      // update history
      let tmpHistory = [...sectionHistory]
      setSectionHistory(tmpHistory)
      tmpHistory.push(sectionIndex) 
    } 

    // We are going backwards just go down through history
    if (!forward) {
      let tmpHistory = [...sectionHistory]

      // Do nothing they are on the first page
      if (tmpHistory.length <= 1) return
      let lastSection = tmpHistory[tmpHistory.length - 1]
      tmpHistory.pop()
      setSectionHistory(tmpHistory)
      setSectionIndex(lastSection)
    } 
    // Lets process the go tos
    else {

      let hasGotos = form[sectionIndex].questions.find(x => x.goTos.length > 0)
      if (!hasGotos) {
        // Set new index
        goToPage(sectionIndex + 1)
      } else {
        for (const question of form[sectionIndex].questions) {
          if (!question.returnAPIName) continue

          for (const goTo of question.goTos) {
            // avoid continuos loop
            if (goTo.section === sectionIndex) continue

            let value = answers[question.returnAPIName]
            switch (goTo.evaluator) {
              case GoToEvaluator.GT:
                if (value > goTo.value) {
                  goToPage(goTo.section)
                  return
                }
                break
              case GoToEvaluator.GTE:
                if (value >= goTo.value) {
                  goToPage(goTo.section)
                  return
                }
                break
              case GoToEvaluator.LS:
                if (value < goTo.value) {
                  goToPage(goTo.section)
                  return
                }
                break
              case GoToEvaluator.LSE:
                if (value <= goTo.value) {
                  goToPage(goTo.section)
                  return
                }
                break
              case GoToEvaluator.EQ:
                if (value == goTo.value) {
                  goToPage(goTo.section)
                  return
                }
                break
              case GoToEvaluator.NEQ:
                if (value != goTo.value) {
                  goToPage(goTo.section)
                  return
                }
                break
              case GoToEvaluator.SKIP: 
                goToPage(goTo.section)
                return 
              break;
            }
          }
        }
        goToPage(sectionIndex + 1)
      }
    }
  }

  const handleDisplayType = (question, index) => {
    console.debug(question)
    switch (question?.questionType) {
      case 'TEXT':
        return (
          <>
            <h3>{question?.questionText}</h3>
            <TextField
              key={'name'}
              title={question?.label}
              type="name"
              name="name"
              value={answers[question.returnAPIName] || ''}
              onChange={(e) => handleAnswers(e.target.value, index)}
              source={'default'}
              disabled={question?.readOnly}
            />
          </>
        )
      case 'INTEGER':
        return (
          <>
            <h3>{question?.questionText}</h3>
            <TextField
              key={'number'}
              title={question?.label}
              type="number"
              name="number"
              placeholder={'Number'}
              value={answers[question.returnAPIName]}
              onChange={(e) => handleAnswers(e.target.value, index)}
              source={'default'}
            />
          </>
        )
      case 'DATE':
        return (
          <>
            <h3>{question?.questionText}</h3>
            <DateSelect
              type="dob"
              name="dob"
              title={question?.label}
              placeholder="Date"
              date={answers[question.returnAPIName]}
              setDate={(val) => handleAnswers(val, index)}
            />
          </>
        )
      case 'TIME':
        return (
          <>
            <h3>{question?.questionText}</h3>
            <TextField
              key={'number'}
              title={question?.label}
              type="time"
              name="number"
              placeholder={'Number'}
              value={answers[question.returnAPIName]}
              onChange={(e) => handleAnswers(e.target.value, index)}
              source={'default'}
            />
          </>
        )
      case 'DROPDOWN':
        return (
          <>
            <h3>{question?.questionText}</h3>
            <Dropdown
              key={'questionType' + answers}
              title={question?.label}
              type="questionType"
              name="questionType"
              placeholder={'Question Type'}
              value={answers[question.returnAPIName]}
              onChange={(e) => {
                handleAnswers(e, index)
              }}
              source={'default'}
              items={[
                { label: 'Text', value: 'text' },
                { label: 'Number', value: 'number' },
                { label: 'Date', value: 'date' },
                { label: 'Dropdown', value: 'dropdown' },
                { label: 'Checkbox', value: 'checkbox' },
                { label: 'File', value: 'file' },
              ]}
            />
          </>
        )
      case 'CHECKBOX':
        return (
          <>
            <h3>{question?.questionText}</h3>
            <Checkbox
              key={'required' + answers}
              title={question?.label}
              type="required"
              name="required"
              items={['Required']}
              placeholder={'Required'}
              onClick={(e) => {
                handleAnswers(!e.target.checked, index)
              }}
              checked={answers[question.returnAPIName]}
              source={'default'}
            />
          </>
        )
      case 'file':
        return (
          <DropZone
            title={question?.label}
            setFile={(value) => setDropFile(value)}
          />
        )
      default:
        return <div>Question Type Not Found</div>
    }
  }
  const handleSubmission = async () => {
    if (state.updating) {
      await updateFormSubmission(patchPath, answers)
      navigate(-1)
      setAlert({
        open: true,
        message: 'Form Completed',
        severity: 'success',
      })
    } else {
      const res = await submitForm(postPath, answers)
      navigate(-1)
      setAlert({
        open: true,
        message: 'Form Completed',
        severity: 'success',
      })
    }
   
  }
  const handleQuestion = () => {
    console.debug(sectionIndex, form.length )
    if (sectionIndex === form.length) {
      return (
        <div>
          Form Complete
          <Button onClick={handleSubmission}>Submit Form</Button>
        </div>
      )
    }

    return (
      <div>
        <h1>{form[sectionIndex]?.sectionName}</h1>
        {
          form[sectionIndex]?.questions.map((question, index) => {
            return (
              <>
                  { handleDisplayType(question, index) }
              </>
            )
          })
        }
      
        <Button onClick={() =>  goToNextScreen(false)}>
          Back
        </Button>
        <Button
          onClick={() => {
            goToNextScreen(true)     
          }}
        >
          Next
        </Button>
      </div>
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Button onClick={() => navigate(-1)}>{'< Back'}</Button>

      {handleQuestion()}
    </div>
  )
}

export default DisplayForms
