import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import MuiTextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import Typography from './Typography'
import ErrorCard from '../../../../ui-library/components/ErrorCard'

const CssTextField = styled(MuiTextField)(({ style, theme, source }) => {
  return {
    '& .MuiOutlinedInput-root': {
      ...theme?.textField,
      ...theme?.textField?.[source],
      ...style,
    },
  }
})

const TextField = (props) => {
  const {
    icon,
    iconPosition,
    title,
    errorType,
    errors,
    touched,
    style,
    source,
  } = props
  const { theme } = useContext(ThemeContext)
  return (
    <div
      style={{
        ...theme?.textField?.container,
        ...theme?.textField?.container?.[source],
        ...style?.container,
      }}
    >
      {title ? (
        <Typography {...props} source={'inputLabel'}>
          {title}
        </Typography>
      ) : (
        <></>
      )}
      <CssTextField
        {...props}
        theme={theme}
        InputProps={{
          [`${iconPosition}Adornment`]: icon ? (
            <InputAdornment position={iconPosition}>{icon}</InputAdornment>
          ) : (
            ''
          ),
        }}
      />
      <ErrorCard type={errorType} errors={errors} touched={touched} />
    </div>
  )
}

TextField.defaultProps = {}

export default TextField
