import React, { useContext } from 'react'
import MUIButton from '@mui/material/Button'
import styled from '@emotion/styled'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import StyledIcon from './StyledIcon'

const CssButton = styled(MUIButton)(({ theme, source, style }) => {
  return { ...theme?.button, ...theme?.button?.[source], ...style }
})

const Button = (props) => {
  const { children, icontype, icon, source, style } = props
  const { theme } = useContext(ThemeContext)
  return (
    <CssButton
      {...props}
      {...{
        [icontype]: (
          <StyledIcon
            style={{
              ...theme?.button?.icon,
              ...theme?.button?.[source]?.icon,
              ...style?.icon,
            }}
            Icon={icon}
          />
        ),
      }}
      theme={theme}
    >
      {children}
    </CssButton>
  )
}

CssButton.defaultProps = {
  variant: 'contained',
}

export default Button
