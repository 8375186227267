import React from 'react'
import Button from '../../../ui-library/components/Button'

const CreateMedia = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Button
        onClick={() => {
          window.open(
            'https://flytt-compliance-med-e865.glide.page/dl/a400f7',
            '_blank',
            'noreferrer'
          )
        }}
      >
        Create Content
      </Button>
      <iframe
        width="100%"
        height="600"
        src="https://flytt-compliance-med-e865.glide.page/dl/a400f7"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
}

export default CreateMedia
