// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBbkTZHWQzXVhJn8kvATNaVEI0nOree5uc',
  authDomain: 'flytt-engine.firebaseapp.com',
  projectId: 'flytt-engine',
  storageBucket: 'flytt-engine.appspot.com',
  messagingSenderId: '872608069446',
  appId: '1:872608069446:web:04209492b0ae303ef09747',
  measurementId: 'G-VVHV35Y5M7',
}

// Initialize Firebase
const startFirebase = () => initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)

export default startFirebase
