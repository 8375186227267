import React, { useContext, useEffect, useState } from 'react'
import TextField from '../../../../ui-library/components/TextField'
import Dropdown from './Dropdown'
import Typography from './Typography'
import IconButton from './IconButton'
import Checkbox from './Checkbox'
import Button from './Button'
import { createForm, getRole } from '@appt-digital/frontend-api-lib-flytt'
import { UserContext } from '../../../../context/UserContext'
import { AlertContext } from '../../../../context/AlertContext'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import { updateForm } from '@appt-digital/frontend-api-lib-flytt'

const QuestionCreator = (props) => {
  const { user } = useContext(UserContext)
  const { state } = props
  const [formBuilder, setFormBuilder] = useState(state?.form ? [...state.form] : [])
  const [name, setName] = useState(state?.name ? state?.name : '')
  const [description, setDescription] = useState(state?.description ? state.description : '')
  const [role, setRole] = useState()
  const { setAlert } = useContext(AlertContext)
  const navigate = useNavigate()

  useEffect(() => {
    const getData = async () => {
      const res = await getRole()
      setRole(res?.data?.[0]?.id)
    }
    getData()
  }, [])

  const questionTypeOptions = [
    // All of these need more thought as they will not be trivial to implement
    // SELECT = 'SELECT',
    // MULTI_SELECT = 'MULTI_SELECT',
    // DROPDOWN = 'DROPDOWN',
    // OBJECT_DROPDOWN = 'OBJECT_DROPDOWN',
    // CHECKBOX = 'CHECKBOX',
    // OPTION = 'OPTION',
    // IMAGE_UPLOAD = 'IMAGE_UPLOAD' 

    { label: 'Text', value: 'TEXT' },
    { label: 'Multitext', value: 'MULTITEXT' },
    { label: 'Number', value: 'INTEGER' },
    { label: 'Date', value: 'DATE' },
    { label: 'Time', value: 'TIME' },
    { label: 'Date time', value: 'DATETIME' },
    { label: 'Dropdown', value: 'DROPDOWN' },
    { label: 'Checkbox', value: 'CHECKBOX' },
    { label: 'Boolean', value: 'BOOLEAN' },
    { label: 'Money', value: 'MONEY' },
    { label: 'Signature', value: 'SIGNATURE' },
    { label: 'File', value: 'file' },
  ]

  const goToEvaluator = [
    { label: 'Greater Than', value: '>' },
    { label: 'Greater Than Or Equal', value: '>=' },
    { label: 'Less Than', value: '<' },
    { label: 'Less Than Or Equal', value: '<=' },
    { label: 'Equal', value: '=' },
    { label: 'Not Equal', value: '!=' },
    { label: 'Skip', value: 'Skip' },
  ]

  const handleSubmit = async () => {

    // We are updating
    if (state) {
      await updateForm(state?.id, { 
        name,
        description,
        form: JSON.stringify(formBuilder) })
      navigate(-1)
      setAlert({
        open: true,
        message: 'Form Updated',
        severity: 'success',
      })
    }

    // We are creating
    else {
      const data = {
        name,
        description,
        form: `${JSON.stringify(formBuilder)}`,
        roleAccess: [{ id: role }],
        userAccess: [
          {
            id: user?.id,
          },
        ],
        organisationWideAccess: true,
      }

      await createForm(data)
      navigate(-1)
      setAlert({
        open: true,
        message: 'Form Created',
        severity: 'success',
      })
    }
  }

  const handleInput = (sectionIndex, questionIndex, value, name) => {
    const updatedQuestions = [...formBuilder]
    updatedQuestions[sectionIndex].questions[questionIndex][name] = value
    setFormBuilder(updatedQuestions)
  }

  const getPossibleGoToQuestions = (sectionIndex, questionIndex, goToIndex, section) => {
    let ret = []
    formBuilder[sectionIndex].questions[questionIndex].goTos[goToIndex].section ?
      ret = formBuilder[formBuilder[sectionIndex].questions[questionIndex].goTos[goToIndex].section].questions.map((question, qindex) => {
        return { label: question.questionText, value: qindex }
      }) :
      ret = section.questions?.map((question, qindex) => {
        return { label: question.questionText, value: qindex }
      })

    if (!ret) return []

    return ret
  }

  const renderSections = (sections) => {
    return (
      <>
        {
          sections?.map((section, sectionIndex) => {
            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  gap: 10,
                }}
              >
                <Typography source={'h1'}>{sectionIndex + 1}. {section.sectionName}</Typography>
                <TextField
                  key={'sectionName' + sectionIndex}
                  title={'Section Name'}
                  name={'sectionName' + sectionIndex}
                  placeholder={'Section Name'}
                  value={section.sectionName}
                  onChange={(e) => {
                    let updateBuilder = [...formBuilder]
                    updateBuilder[sectionIndex].sectionName = e.target.value
                    setFormBuilder(updateBuilder)
                  }}
                />
                <br />
                <IconButton
                  source={'dynamicForm'}
                  onClick={() => {
                    let updatedBuilder = [...formBuilder]
                    updatedBuilder[sectionIndex].questions.push({
                      questionId: updatedBuilder[sectionIndex].questions.length + 1,
                      returnAPIName: '',
                      questionText: '',
                      questionType: '',
                      required: false,
                      error: '',
                      goTos: [],
                      label: '',
                      returnAPIStructure: [],
                      options: [],
                      objects: [],
                      objectMapValue: '',
                      objectIDValue: '',
                      value: '',
                      imageUploadUrl: '',
                      readOnly: false,
                      returnAPIName: uuid()
                    })
                    setFormBuilder(updatedBuilder)
                  }}
                >
                  Add Question
                </IconButton>
                <Typography source={'h5'}>Questions</Typography>
                {
                  section.questions.map((question, questionIndex) => {
                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <TextField
                            key={'questionText' + sectionIndex + '-' + questionIndex}
                            title={'Question Text'}
                            type="questionText"
                            name="questionText"
                            placeholder={'Question Text'}
                            value={question.questionText}
                            onChange={(e) =>
                              handleInput(sectionIndex, questionIndex, e.target.value, e.target.name)
                            }
                            source={'default'}
                          />
                          <TextField
                            key={'labelText' + sectionIndex + '-' + questionIndex}
                            title={'Label Text'}
                            type="labelText"
                            name="label"
                            placeholder={'Label Text'}
                            value={question.label}
                            onChange={(e) =>
                              handleInput(sectionIndex, questionIndex, e.target.value, e.target.name)
                            }
                            source={'default'}
                          />
                          <Dropdown
                            key={'questionType' + sectionIndex + '-' + questionIndex}
                            title={'Question Type'}
                            type="questionType"
                            name="questionType"
                            placeholder={ questionTypeOptions.find(function (x) {
                              return x.value === question.questionType;
                            })?.label}
                            value={
                              questionTypeOptions.find(function (x) {
                                return x.value === question.questionType;
                              })?.label
                            }
                            onChange={(e) => {
                              handleInput(sectionIndex, questionIndex, e.value, 'questionType')
                            }}
                            source={'default'}
                            items={questionTypeOptions}
                          />
                          <Checkbox
                            key={'required' + sectionIndex + '-' + questionIndex}
                            title={'Required'}
                            type="required"
                            name="required"
                            items={['Required']}
                            placeholder={'Required'}
                            onClick={(e) => {
                              handleInput(sectionIndex, questionIndex, !e.target.checked, 'required')
                            }}
                            checked={question?.required || false}
                            source={'default'}
                          />
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <TextField
                            key={'error' + sectionIndex + '-' + questionIndex}
                            title={'Error'}
                            type="error"
                            name="error"
                            placeholder={'Error'}
                            value={question?.error}
                            onChange={(e) =>
                              handleInput(sectionIndex, questionIndex, e.target.value, e.target.name)
                            }
                            source={'default'}
                          />
                          <Checkbox
                            key={'readOnly' + sectionIndex + '-' + questionIndex}
                            title={'Read Only'}
                            type="readOnly"
                            name="readOnly"
                            items={['readOnly']}
                            placeholder={'readOnly'}
                            source={'default'}
                            onClick={(e) => {
                              handleInput(sectionIndex, questionIndex, !e.target.checked, 'readOnly')
                            }}
                            checked={question?.readOnly || false}
                          />
                          <IconButton
                            source={'dynamicForm'}
                            onClick={() => {
                              let updated = [...formBuilder]
                              updated[sectionIndex].questions[questionIndex].goTos.push({
                                index: 0,
                                value: '',
                                evaluator: '',
                                section: 0
                              })
                              setFormBuilder(updated)
                            }}
                          >
                            Add GoTo
                          </IconButton>

                          {question.goTos.map((goTo, goToIndex) => {
                            return (
                              <>
                                <Dropdown
                                  key={'goToSection' + sectionIndex + '-' + questionIndex}
                                  title={'Go To Section'}
                                  type="goToEvaluator"
                                  name="goToEvaluator"
                                  placeholder={ sections.map(
                                    (x, index) => { return { label: x.sectionName, value: index } }).find((val) => {
                                      return val.value === goTo.section;
                                    })?.label
                                  }
                                  value={
                                    sections.map(
                                      (x, index) => { return { label: x.sectionName, value: index } }).find((val) => {
                                        return val.value === goTo.section;
                                      })?.label
                                  }
                                  onChange={(e) => {
                                    let updatedGoto = [...formBuilder]
                                    updatedGoto[sectionIndex].questions[questionIndex].goTos[goToIndex].section = e.value
                                    setFormBuilder(updatedGoto)
                                  }}
                                  source={'default'}
                                  items={sections.map((x, index) => { return { label: x.sectionName, value: index } })}
                                />

                                <TextField
                                  key={'goToValue' + sectionIndex + '-' + questionIndex + '-' + goToIndex}
                                  title={'Question Value'}
                                  type="goToValue"
                                  name="goToValue"
                                  placeholder={goTo?.value}
                                  value={goTo?.value}
                                  onChange={(e) => {
                                    let updatedGoto = [...formBuilder]
                                    updatedGoto[sectionIndex].questions[questionIndex].goTos[goToIndex].value = e.target.value
                                    setFormBuilder(updatedGoto)
                                  }}
                                  source={'default'}
                                />

                                <Dropdown
                                  key={'goToEvaluator' + sectionIndex + '-' + questionIndex}
                                  title={'Evaluator'}
                                  type="goToEvaluator"
                                  name="goToEvaluator"
                                  placeholder={goToEvaluator.find(function (x) {
                                    return x.value === goTo.evaluator;
                                  })?.label}
                                  value={goToEvaluator.find(function (x) {
                                    return x.value === goTo.evaluator;
                                  })?.label}
                                  onChange={(e) => {
                                    let updatedGoto = [...formBuilder]
                                    updatedGoto[sectionIndex].questions[questionIndex].goTos[goToIndex].evaluator = e.value
                                    setFormBuilder(updatedGoto)
                                  }}
                                  source={'default'}
                                  items={goToEvaluator}
                                />


                                <IconButton
                                  source={'dynamicFormDelete'}
                                  onClick={() => {
                                    let updated = [...formBuilder]
                                    updated[sectionIndex].questions[questionIndex].goTos.splice(goToIndex, 1)
                                    setFormBuilder(updated)
                                  }}
                                >
                                  Remove GoTo
                                </IconButton>
                              </>
                            )
                          })}

                        </div>
                        <IconButton
                          source={'dynamicFormDelete'}
                          onClick={() => {
                            let updated = [...formBuilder]
                            updated[sectionIndex].questions.splice(questionIndex, 1)
                            setFormBuilder(updated)
                          }}
                        >
                          -
                        </IconButton>
                      </>
                    )
                  })

                }
                <IconButton
                  source={'dynamicForm'}
                  onClick={() => {
                    let updated = [...formBuilder]
                    updated.splice(sectionIndex, 1)
                    setFormBuilder(updated)
                  }}
                >
                  Remove Page
                </IconButton>
              </div>
            )
          })
        }
      </>
    )
  }

  return (
    <>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            key={'name'}
            title={'Form Name'}
            type="name"
            name="name"
            placeholder={'Name'}
            value={name}
            onChange={(e) => setName(e.target.value)}
            source={'default'}
          />
          <TextField
            key={'description'}
            title={'Form Description'}
            type="description"
            name="description"
            placeholder={'Description'}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            source={'default'}
          />
        </div>

        {renderSections(formBuilder)}

        <IconButton
          source={'dynamicForm'}
          onClick={() => {
            setFormBuilder([...formBuilder, {
              questions: [],
              sectionName: ''
            }])
          }}
        >
          Add Page
        </IconButton>
        {formBuilder.map((item) => Object.values(item).toString())}
      </div>
      <Button onClick={async () => handleSubmit()}>Submit</Button>
    </>
  )
}

export default QuestionCreator
