import React, { useEffect, useState } from 'react'
import TimeoutWarningModal from './TimeoutWarningModal'
import { addEventListeners, removeEventListeners } from './eventListenerUtil'
import { refresh } from '@appt-digital/frontend-api-lib-flytt'

function TimeoutLogicDialog() {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false)
  useEffect(() => {
    const createTimeout1 = () =>
      // set time for log out dialog
      setTimeout(() => {
        setWarningModalOpen(true)
      }, 240000)

    let timeout = isWarningModalOpen ? null : createTimeout1()
    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout)
        timeout = createTimeout1()
      }
    }
    // Initialization
    addEventListeners(listener)

    // Cleanup
    return () => {
      removeEventListeners(listener)
      clearTimeout(timeout)
    }
  }, [isWarningModalOpen])
  return (
    <div>
      {isWarningModalOpen && (
        <TimeoutWarningModal
          isOpen={isWarningModalOpen}
          onRequestClose={async () => {
            await refresh()
            setWarningModalOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default TimeoutLogicDialog
