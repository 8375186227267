import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../../screens/auth/Login'
import Register from '../../screens/auth/Register'
import Reset from '../../screens/auth/Reset'
import NotFound from '../../screens/NotFound'
import Success from '../../screens/Success'
import Failure from '../../screens/Failure'
import Password from '../../screens/auth/Password'
import KitchenSink from '../../ui-library/KitchenSink'
import Auth2FA from '../../screens/auth/Auth2FA'
import Gdpr from '../../screens/dashboard/users/system/GDPR'
import DashboardContainer from '../../ui-library/components/DashboardContainer'
import UsersList from '../../screens/dashboard/users/system/UsersList'
import EditUser from '../../screens/dashboard/users/system/EditUser'
import AddUser from '../../screens/dashboard/users/system/AddUser'
import AddAppUser from '../../screens/dashboard/users/app/AddUser'
import EditAppUser from '../../screens/dashboard/users/app/EditUser'
import AppUsersList from '../../screens/dashboard/users/app/UsersList'
import GdprAppUser from '../../screens/dashboard/users/app/GDPR'
import CreateMedia from '../../screens/dashboard/media/CreateMedia'
import CreateForms from '../../screens/dashboard/forms/create/CreateForms'
import { isMobile } from 'react-device-detect'
import Forms from '../../screens/dashboard/forms/Forms'

import EmbedEdit from '../../screens/dashboard/forms/Embed-Edit'
import EmbedSubmit from '../../screens/dashboard/forms/Embed-Submit'

import ListForms from '../../screens/dashboard/forms/edit/ListForms'
import EditForm from '../../screens/dashboard/forms/edit/EditForm'
import DisplayForms from '../../screens/dashboard/forms/display/DisplayForms'
import ViewForms from '../../screens/dashboard/forms/display/ViewForms'
import ListSubmitted from '../../screens/dashboard/forms/display/submitted/ListSubmitted'
import AppLogin from '../../screens/auth/AppLogin'

function Router() {
  if (isMobile) {
    return (
      <Routes>
        <Route path="/app-login" element={<AppLogin />} />
        <Route path="/dashboard" element={<Forms />} />
        <Route path="/dashboard/forms" element={<Forms />} />
        <Route path="/dashboard/embed/forms/edit" element={<EmbedEdit />} />
        <Route path="/dashboard/embed/forms/submit" element={<EmbedSubmit />} />
        <Route path="/dashboard/forms/create" element={<CreateForms />} />
        <Route path="/dashboard/forms/list" element={<ListForms />} />
        <Route path="/dashboard/forms/display" element={<DisplayForms />} />
        <Route path="/dashboard/forms/edit" element={<EditForm />} />
        <Route path="/dashboard/forms/view" element={<ViewForms />} />
        <Route path="/dashboard/forms/submitted" element={<ListSubmitted />} />
        <Route path="/dashboard/create-media" element={<CreateMedia />} />
      </Routes>
    )
  }
  return (
    <Routes>
      {/*  AUTH ROUTES */}
      <Route path="/app-login" element={<AppLogin />} />

      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/reset" element={<Reset />} />
      <Route path="/authenticate" element={<Auth2FA />} />
      <Route path="/reset-password" element={<Password />} />
      {/* DASHBOARD ROUTES */}
      <Route path="/dashboard" element={<DashboardContainer />} />
      <Route path="/dashboard/create-media" element={<CreateMedia />} />
      <Route path="/dashboard/system" element={<UsersList />} />
      <Route path="/dashboard/system/add" element={<AddUser />} />
      <Route path="/dashboard/system/edit" element={<EditUser />} />
      <Route path="/dashboard/app" element={<AppUsersList />} />
      <Route path="/dashboard/app/add" element={<AddAppUser />} />
      <Route path="/dashboard/app/edit" element={<EditAppUser />} />
      <Route path="/dashboard/APP/gdpr" element={<GdprAppUser />} />
      <Route path="/dashboard/system/gdpr" element={<Gdpr />} />
      <Route path="/dashboard/forms" element={<Forms />} />
      <Route path="/dashboard/embed/forms/edit" element={<EmbedEdit />} />
      <Route path="/dashboard/embed/forms/submit" element={<EmbedSubmit />} />
      <Route path="/dashboard/forms/create" element={<CreateForms />} />
      <Route path="/dashboard/forms/list" element={<ListForms />} />
      <Route path="/dashboard/forms/display" element={<DisplayForms />} />
      <Route path="/dashboard/forms/edit" element={<EditForm />} />
      <Route path="/dashboard/forms/view" element={<ViewForms />} />
      <Route path="/dashboard/forms/submitted" element={<ListSubmitted />} />
      {/*  Videos */}

      {/*  Subscriptions */}
      {/* MISC */}
      <Route path="/sink" element={<KitchenSink />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/success" element={<Success />} />
      <Route path="/failure" element={<Failure />} />
    </Routes>
  )
}

export default Router
