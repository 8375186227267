import React, { useContext } from 'react'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import StyledIcon from './StyledIcon'
import Select, { components } from 'react-select'
import Typography from './Typography'

function Dropdown(props) {
  const { theme } = useContext(ThemeContext)
  const {
    id,
    name,
    items,
    inputLabel,
    Icon = ArrowDropDownIcon,
    title,
    source,
    style,
    onChange,
    value,
    ...rest
  } = props
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <StyledIcon
          style={{
            ...theme?.dropDown?.icon,
            ...theme?.dropDown?.[source]?.icon,
            ...style?.icon,
          }}
          Icon={Icon}
        />
      </components.DropdownIndicator>
    )
  }
  return (
    <div
      style={{
        ...theme?.dropDown?.container,
        ...theme?.dropDown?.[source]?.container,
        ...style?.container,
      }}
    >
      {title ? (
        <Typography {...props} source={'inputLabel'}>
          {title}
        </Typography>
      ) : (
        <></>
      )}
      <Select
        {...rest}
        // defaultValue={}
        styles={{
          ...theme?.dropDown?.select,
          ...theme?.dropDown?.[source]?.select,
          ...style?.select,
        }}
        unstyled
        name="colors"
        options={items}
        className="basic-multi-select"
        classNamePrefix="select"
        components={{ DropdownIndicator }}
        value={value}
        onChange={onChange}
        noOptionsMessage={() => ''}
      />
    </div>
  )
}

export default Dropdown

// for custom styling of react-select see https://react-select.com/styles
