import React, { useState } from 'react'
import defaultTheme from '../../screens/dashboard/forms/components/styles/defaultTheme'

export const ThemeContext = React.createContext(defaultTheme)
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme)
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
