import React, { useContext, useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useLocation, useNavigate } from 'react-router-dom'
import Router from './navigation/routing/Router'
import { refresh, setApiUrl } from '@appt-digital/frontend-api-lib-flytt'
import { UserContext } from './context/UserContext'
import Alerts from './ui-library/components/Alerts'
import { AlertContext } from './context/AlertContext'
import Grid from '@mui/material/Grid'
import { StyledEngineProvider } from '@mui/material/styles'
import LoadingAnim from './ui-library/components/loadingAnim/LoadingAnim'
import { ThemeContext } from './ui-library/components/ThemeContext'
import routeHandler from './handlers/routeHandler'
import defaultTheme from './ui-library/styles/defaultTheme'
import startFirebase from './utils/firebase.config'
// import Button from './ui-library/components/Button'
// import defaultTheme from './ui-library/styles/defaultTheme'
// import tasTheme from './ui-library/styles/tasTheme'

function AppChild() {
  const url =
    process.env.REACT_APP_API_URL || 'https://stage-api-portal.ontheappt.cloud/'
  setApiUrl(url)
  const navigate = useNavigate()
  const { user } = useContext(UserContext)
  const { alert, setAlert } = useContext(AlertContext)
  const location = useLocation()
  const { setTheme } = useContext(ThemeContext)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const handleRoutes = async () => {
      console.debug('Setting url')
      setApiUrl(url)
      window.addEventListener(
        'storage',
        async () => await routeHandler(navigate, user)
      )
      await refresh()
      setLoading(false)
      await routeHandler(navigate, location, user)
    }
    handleRoutes()
  }, [location])

  useEffect(() => {
    console.debug('Setting url')
    startFirebase()
    setTheme(defaultTheme)
    setApiUrl(url)
    setTimeout(async () => {
      await refresh()
    }, 240000)
  }, [])

  // stops requests running before API url is updated on refresh and load
  if (loading)
    return (
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingAnim />
      </div>
    )

  return (
    <>
      {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
      {/*  <Button onClick={() => setTheme(theme)}>Flytt-Compliance Theme</Button> */}
      {/*  <Button onClick={() => setTheme(tasTheme)}>TAS Theme</Button> */}
      {/*  <Button onClick={() => setTheme(defaultTheme)}> Default Theme </Button> */}
      {/*  <Button onClick={() => setTheme('')}> MUI Theme </Button> */}
      {/* </div> */}
      <HelmetProvider>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=0.86, maximum-scale=5.0, minimum-scale=0.86"
          />
          <link rel="stylesheet" href="https://use.typekit.net/vfo4oyg.css" />
        </Helmet>
      </HelmetProvider>
      <StyledEngineProvider injectFirst>
        <Grid container>
          <Router />
        </Grid>
        <Alerts alert={alert} setAlert={setAlert} />
      </StyledEngineProvider>
    </>
  )
}

export default AppChild
