import React, { useContext } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import { styled } from '@mui/material/styles'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import MuiCheckbox from '@mui/material/Checkbox'
import Typography from './Typography'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import StyledIcon from './StyledIcon'

const CssCheckBox = styled(MuiCheckbox)((props) => {
  const { theme, source, style } = props
  return { ...theme?.checkBox, ...theme?.checkBox?.[source], ...style }
})

const Checkbox = (props) => {
  const {
    iconChecked = RadioButtonCheckedIcon,
    iconUnChecked = RadioButtonUncheckedIcon,
    source,
    style,
    title,
  } = props
  const { theme } = useContext(ThemeContext)
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <FormControlLabel
          control={
            <>
              {title}
              <CssCheckBox
                {...props}
                icon={
                  <StyledIcon
                    style={{
                      ...theme?.checkBox?.icon,
                      ...theme?.checkBox?.[source]?.icon,
                      ...style?.icon,
                    }}
                    Icon={iconUnChecked}
                  />
                }
                checkedIcon={
                  <StyledIcon
                    style={{
                      ...theme?.checkBox?.icon,
                      ...theme?.checkBox?.[source]?.icon,
                      ...style?.icon,
                    }}
                    Icon={iconChecked}
                  />
                }
                theme={theme}
              />
            </>
          }
          label={
            <Typography source="checkbox">
              {/* {item?.displayName ? item?.displayName : item} */}
            </Typography>
          }
        />
      </div>
    </div>
  )
}

export default Checkbox
