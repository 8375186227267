import React from 'react'
import QuestionCreator from '../components/QuestionCreator'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../components/Button'

const EditForm = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  return (
    <>
      <Button onClick={() => navigate(-1)}>{'< Back'}</Button>
      <QuestionCreator state={state} />
    </>
  )
}

export default EditForm
