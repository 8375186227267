// import { getUserAuditTrail } from '@appt-digital/frontend-api-lib-flytt'
import React, { useContext, useState } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '../../../../ui-library/components/Typography'
import { useFormik } from 'formik'
import { addUserSchema } from '../../../../config/validation'
import {
  deleteAppUser,
  editAppUser,
  resetAppUser,
} from '../../../../handlers/requests/users/appUsersHandler'
import ConfirmationModal from '../../../../ui-library/components/dialogs/ConfirmationModal'
import { AlertContext } from '../../../../context/AlertContext'
import TextField from '../../../../ui-library/components/TextField'
import Dropdown from '../../../../ui-library/components/Dropdown'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '../../../../ui-library/components/Button'
import Link from '../../../../ui-library/components/Link'
import { FaUser } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import DashboardContainer from '../../../../ui-library/components/DashboardContainer'
import TabHeader from '../../../../ui-library/components/TabHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import toCapitalised from '../../../../utils/capitaliseString'
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '../../../../ui-library/components/DataTable'
import uuid from 'react-uuid'

function EditUser() {
  const [setLoading] = useState(false)
  const { setAlert } = useContext(AlertContext)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirmReset, setConfirmReset] = useState(false)
  const [audit, setAudit] = useState([])
  const { theme } = useContext(ThemeContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const initialEdit = {
    firstname: state?.user?.firstname || '',
    lastname: state?.user?.lastname || '',
    emailAddress: state?.user?.email || '',
    departmentKey: state?.user?.departmentKey || '',
    jobTitle: state?.user?.jobTitle || '',
  }

  const [selectedOrgs] = useState([])
  const [title, setTitle] = useState(
    {
      label: state?.user?.title,
      value: state?.user?.title,
    } || { label: 'Mrs', value: 'mrs' }
  )
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialEdit,
      validationSchema: addUserSchema,
      onSubmit: async () => await handleEdit(),
    })
  const handleEdit = async () => {
    await editAppUser(
      state?.user?.id,
      { values, title, selectedOrgs },
      setAlert,
      setLoading
    )
    navigate('/dashboard/system')
  }
  // useEffect(() => {
  //   const getAuditTrail = async () => {
  //     const res = await getUserAuditTrail(state?.user?.id)
  //     setAudit(res?.data)
  //   }
  //   getAuditTrail()
  // }, [])
  return (
    <>
      <DashboardContainer>
        <TabHeader
          title={'Edit'}
          username={
            state?.user?.firstname !== null &&
            state?.user?.firstname !== undefined &&
            state?.user?.lastname !== null &&
            state?.user?.lastname !== undefined &&
            `${toCapitalised(state?.user?.firstname)} ${toCapitalised(
              state?.user?.lastname
            )}`
          }
        >
          <Paper style={theme?.dashboard?.container}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <Link
                  type="submit"
                  onClick={() => {
                    navigate('/dashboard/system')
                  }}
                >
                  {`< Back`}
                </Link>
                <Typography source={'h1'}>User Details</Typography>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 10,
                    marginBottom: 5,
                  }}
                >
                  <Button
                    onClick={() => {
                      setConfirmReset(true)
                    }}
                    icon={FaUser}
                    icontype={'endIcon'}
                    source={'altButton'}
                  >
                    Reset Password
                  </Button>
                  <Button
                    onClick={() => {
                      setConfirmDelete(true)
                    }}
                    icon={MdDeleteForever}
                    icontype={'endIcon'}
                  >
                    Delete User
                  </Button>
                </div>
                <form onSubmit={handleSubmit}>
                  <Dropdown
                    type="title"
                    id={'title'}
                    name={'title'}
                    title={'Title'}
                    setSelected={setTitle}
                    onBlur={handleBlur}
                    selected={title}
                    items={[
                      { label: 'Mr', value: 'Mr' },
                      { label: 'Mrs', value: 'Mrs' },
                      { label: 'Miss', value: 'Miss' },
                      { label: 'Ms', value: 'Ms' },
                      { label: 'Sir', value: 'Sir' },
                    ]}
                    Icon={ArrowDropDownIcon}
                    errorType={'type'}
                    errors={errors}
                    touched={touched}
                    style={{ container: { width: '49.5%', marginBottom: 5 } }}
                  />
                  <div
                    style={{ display: 'flex', flexDirection: 'row', gap: 10 }}
                  >
                    <TextField
                      type="firstname"
                      name="firstname"
                      placeholder="First Name"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'firstname'}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="lastname"
                      name="lastname"
                      placeholder="Last Name"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'lastname'}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div
                    style={{ display: 'flex', flexDirection: 'row', gap: 10 }}
                  >
                    <TextField
                      type="jobTitle"
                      name="jobTitle"
                      placeholder="Job Title"
                      value={values.jobTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`emailAddress`}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="departmentKey"
                      name="departmentKey"
                      placeholder="Department"
                      value={values.departmentKey}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`department`}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <TextField
                    type="emailAddress"
                    name="emailAddress"
                    placeholder="Email"
                    value={values.emailAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`emailAddress`}
                    errors={errors}
                    touched={touched}
                    style={{ width: '70.5%' }}
                  />
                  <Button type="submit">Save</Button>
                </form>
                <ConfirmationModal
                  onAccept={async () => {
                    await deleteAppUser(state?.user?.id, setAlert)
                    navigate('/dashboard/system')
                  }}
                  isOpen={confirmDelete}
                  onRequestClose={() => setConfirmDelete(false)}
                  type={'Delete'}
                  text={'Are you sure you wish to delete this user?'}
                  icon={MdDeleteForever}
                />
                <ConfirmationModal
                  onAccept={async () => {
                    await resetAppUser(state?.user?.email, setAlert)
                    setConfirmReset(false)
                  }}
                  isOpen={confirmReset}
                  onRequestClose={() => setConfirmReset(false)}
                  type={'Reset'}
                  text={'Are you sure you wish to reset this users password?'}
                  icon={FaUser}
                />
              </div>
              <div
                style={{
                  margin: 20,
                  borderLeft: '1px solid grey',
                  minHeight: 600,
                }}
              />
              <div style={{ paddingLeft: 30, paddingRight: 30 }}>
                <Typography source={'h2'} style={{ marginTop: 30 }}>
                  Audit Trail
                </Typography>
                <TableContainer source={'audit'} theme={theme}>
                  <Table source={'audit'} theme={theme}>
                    <TableHead source={'audit'} theme={theme}>
                      {['Time', 'Action']?.map((header, index) => {
                        return (
                          <TableCell source={'audit'} key={index} theme={theme}>
                            <Typography>{header}</Typography>
                          </TableCell>
                        )
                      })}
                    </TableHead>
                    {audit?.map((item) => {
                      return (
                        <TableRow source={'audit'} theme={theme} key={uuid()}>
                          <TableCell source={'audit'} theme={theme}>
                            {new Date(item?.updatedAt).toLocaleString()}
                          </TableCell>
                          <TableCell source={'audit'} theme={theme}>
                            {item?.description}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </Table>
                </TableContainer>
              </div>
            </div>
          </Paper>
        </TabHeader>
      </DashboardContainer>
    </>
  )
}

export default EditUser
