import React, { useContext, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import LoadingAnim from '../../ui-library/components/loadingAnim/LoadingAnim'
import { UserContext } from '../../context/UserContext'
import { embedLogin } from '@appt-digital/frontend-api-lib-flytt'
import { useNavigate } from 'react-router-dom'

export default function AppLogin() {
  const { user, setUser } = useContext(UserContext)
  const navigate = useNavigate()
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const getEmbedLogin = async () => {
      const res = await embedLogin(queryParameters.get('access_token'))
      if (res.status != 200) {
        // show error ??
      } else {
        setUser(res.data)
        navigate('/dashboard')
      }
    }
    getEmbedLogin()
  }, [])
  return (
    <Grid item xs={12}>
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingAnim />
      </div>
    </Grid>
  )
}
