import React, { useContext, useState } from 'react'
import Paper from '@mui/material/Paper'
import Typography from '../../../../ui-library/components/Typography'
import { useFormik } from 'formik'
import { addUserSchema } from '../../../../config/validation'
import { addAdminUserHandler } from '../../../../handlers/requests/users/appUsersHandler'
import { AlertContext } from '../../../../context/AlertContext'
import TextField from '../../../../ui-library/components/TextField'
import Dropdown from '../../../../ui-library/components/Dropdown'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Button from '../../../../ui-library/components/Button'
import Link from '../../../../ui-library/components/Link'
import { ThemeContext } from '../../../../ui-library/components/ThemeContext'
import DashboardContainer from '../../../../ui-library/components/DashboardContainer'
import TabHeader from '../../../../ui-library/components/TabHeader'
import { useLocation, useNavigate } from 'react-router-dom'

function AddUser() {
  const [setLoading] = useState(false)
  const { setAlert } = useContext(AlertContext)
  const { theme } = useContext(ThemeContext)
  const { state } = useLocation()
  const navigate = useNavigate()
  const [selectedOrgs] = useState([])
  const [title, setTitle] = useState()
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        firstname: '',
        lastname: '',
        emailAddress: '',
        jobTitle: '',
        departmentKey: '',
      },
      validationSchema: addUserSchema,
      onSubmit: () => handleAdd(),
    })

  const handleAdd = async () => {
    await addAdminUserHandler(
      { values, title, selectedOrgs },
      setAlert,
      setLoading
    )
    navigate('/dashboard/system')
  }

  return (
    <>
      <DashboardContainer>
        <TabHeader title={state?.title}>
          <Paper style={theme?.dashboard?.container}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <Link
                  type="submit"
                  onClick={() => {
                    navigate('/dashboard/system')
                  }}
                >
                  {`< Back`}
                </Link>
                <Typography source={'h1'}>User Details</Typography>

                <Typography source={''}>Title</Typography>
                <form onSubmit={handleSubmit}>
                  <Dropdown
                    type="title"
                    id={'title'}
                    name={'title'}
                    setSelected={setTitle}
                    onBlur={handleBlur}
                    selected={title}
                    items={[
                      { label: 'Mr', value: 'Mr' },
                      { label: 'Mrs', value: 'Mrs' },
                      { label: 'Miss', value: 'Miss' },
                      { label: 'Ms', value: 'Ms' },
                      { label: 'Sir', value: 'Sir' },
                    ]}
                    Icon={ArrowDropDownIcon}
                    errorType={'type'}
                    errors={errors}
                    touched={touched}
                    style={{ container: { width: '49.5%', marginBottom: 5 } }}
                  />
                  <div
                    style={{ display: 'flex', flexDirection: 'row', gap: 10 }}
                  >
                    <TextField
                      type="firstname"
                      name="firstname"
                      placeholder="First Name"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'firstname'}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="lastname"
                      name="lastname"
                      placeholder="Last Name"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={'lastname'}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <div
                    style={{ display: 'flex', flexDirection: 'row', gap: 10 }}
                  >
                    <TextField
                      type="jobTitle"
                      name="jobTitle"
                      placeholder="Job Title"
                      value={values.jobTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`firstname`}
                      errors={errors}
                      touched={touched}
                    />
                    <TextField
                      type="departmentKey"
                      name="departmentKey"
                      placeholder="Department"
                      value={values.departmentKey}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errorType={`department`}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                  <TextField
                    type="emailAddress"
                    name="emailAddress"
                    placeholder="Email"
                    value={values.emailAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errorType={`emailAddress`}
                    errors={errors}
                    touched={touched}
                    style={{ width: '70.5%' }}
                  />
                  <Button type="submit">Save</Button>
                </form>
              </div>
            </div>
          </Paper>
        </TabHeader>
      </DashboardContainer>
    </>
  )
}

export default AddUser
