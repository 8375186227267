import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CircularProgress from '@mui/material/CircularProgress'
import style from '../../style/style'
import { passwordSchema } from '../../config/validation'
import { reset } from '@appt-digital/frontend-api-lib-flytt'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AlertContext } from '../../context/AlertContext'
import LoadingButton from '../../ui-library/components/LoadingButton'
import uiText from '../../resources/uiText'
import PasswordField from '../../ui-library/components/PasswordField'
import Logo from '../../ui-library/components/Logo'
import Typography from '../../ui-library/components/Typography'
import Link from '../../ui-library/components/Link'

export default function Password() {
  const navigate = useNavigate()
  const signupData = uiText.login
  const { setAlert } = useContext(AlertContext)
  const [loading] = useState(false)
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  return (
    <Grid item xs={12}>
      {loading ? (
        <CircularProgress
          size={68}
          sx={{
            color: 'white',
            position: 'absolute',
            margin: 'auto',
            zIndex: 1,
          }}
        />
      ) : (
        <Formik
          validationSchema={passwordSchema}
          initialValues={{
            password: '',
            passwordConfirm: '',
          }}
          onSubmit={async (values) => {
            const req = await reset(token, values.password)
            if (req.name === 'AxiosError') {
              return setAlert({
                open: true,
                severity: 'error',
                message: 'Error sending reset request',
                action: false,
              })
            }
            setAlert({
              open: true,
              severity: 'success',
              message:
                'Password reset successful, if you are an app user, please continue in the app',
              action: false,
            })
            navigate('/login')
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div style={style.auth.container}>
                <div style={style.auth.layout}>
                  <Logo image={''} alt="" />
                  <Typography
                    source={'subtitle'}
                    style={{
                      marginBottom: 60,
                      textAlign: 'center',
                      lineHeight: 0.8,
                      fontSize: 40,
                    }}
                  >
                    Reset Password
                  </Typography>
                  <PasswordField
                    showStrength
                    type="password"
                    name="password"
                    placeholder={signupData.password_label}
                    value={values.password}
                    onChange={handleChange}
                    source={'default'}
                  />
                  <Card
                    className="error"
                    sx={
                      errors.password && touched.password && errors.password
                        ? style.error
                        : {}
                    }
                  >
                    {errors?.password && touched?.password && errors?.password}
                  </Card>
                  <PasswordField
                    type="password"
                    name="passwordConfirm"
                    placeholder={'Confirm Password*'}
                    value={values.passwordConfirm}
                    onChange={handleChange}
                    style={{ marginBottom: 5, marginTop: 5 }}
                    source={'default'}
                  />
                  <Card
                    className="error"
                    sx={
                      errors.passwordConfirm &&
                      touched.passwordConfirm &&
                      errors.passwordConfirm
                        ? style.error
                        : {}
                    }
                  >
                    {errors?.passwordConfirm &&
                      touched?.passwordConfirm &&
                      errors?.passwordConfirm}
                  </Card>
                  <LoadingButton source={'authButton'} type={'submit'}>
                    {signupData.submit_label}
                  </LoadingButton>
                  <Link source={'link'} onClick={() => navigate('/login')}>
                    {'< Back'}
                  </Link>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </Grid>
  )
}
