import React, { useEffect, useState } from 'react'
import Button from '../components/Button'
import { deleteForm, getForms } from '@appt-digital/frontend-api-lib-flytt'
import { useNavigate } from 'react-router-dom'
import IconButton from '../components/IconButton'
import { MdDeleteForever } from 'react-icons/md'

const ListForms = () => {
  const navigate = useNavigate()
  const [forms, setForms] = useState([])
  const getData = async () => {
    const res = await getForms()
    setForms(res?.data)
    console.log(res)
  }
  useEffect(() => {
    getData()
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Button onClick={() => navigate(-1)}>{'< Back'}</Button>

      {forms?.map((form) => (
        <div style={{ flexDirection: 'row', padding: 50 }}>
          <Button
            key={form?.id}
            onClick={() => navigate('/dashboard/forms/edit', { state: form })}
          >
            {form?.name}
          </Button>
          <IconButton
            onClick={async () => {
              await deleteForm(form?.id)
              await getData()
            }}
          >
            <MdDeleteForever />
          </IconButton>
        </div>
      ))}
    </div>
  )
}

export default ListForms
